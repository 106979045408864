<template>
  <div>
    <AddLv />
  </div>
</template>

<script>
import AddLv from '../AddLv.vue'
export default {
    components: {
        AddLv
    }

}
</script>

<style>

</style>